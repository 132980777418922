import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { toast } from 'react-toastify';
import { ArrowLeftIcon } from '@primer/octicons-react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import CustomFetch from '../../components/fetch';

export default function VerifyPage() {
    const location = useLocation();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');

    const handleReset = (e) => {
        if (e) {
            e.preventDefault();

            let _error = '';

            if (password === '') {
                _error += 'Please enter your new password.\r\n';
            }

            if (_error === '') {
                CustomFetch(
                    `${process.env.GATSBY_API_URL}/login/reset`,
                    'POST',
                    {
                        code: code,
                        password: password,
                        password_confirmation: password,
                    },
                    function (result) {
                        if (result.error === '') {
                            toast.success(
                                'You have reset your login to the new specified password.'
                            );

                            navigate('/portal');
                        } else {
                            toast.error(String(result.error));
                        }
                    },
                    function (error) {
                        toast.error(String(error));
                    }
                );
            } else {
                toast.error(String(_error));
            }
        }
    };

    useEffect(() => {
        if (location.search) {
            // Assuming the URL is "http://example.com/?code=asdasddas"

            // Get the current URL
            const currentUrl = new URL(
                `https://throughlife.com.au/portal/verify/${location.search}`
            );

            // Get the value of the "code" parameter
            const code = currentUrl.searchParams.get('code');

            if (code) {
                setCode(code);
            } else {
                navigate('/portal/forgot');
            }
        } else {
            navigate('/portal/forgot');
        }
    }, []);

    return (
        <Layout>
            <Seo
                title="Portal"
                description="Client Portal Reset Your Password"
            />
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12 col-bleed portal--content">
                            <h1>
                                Reset Your Password
                                <span>Please enter your new password.</span>
                            </h1>
                            <form onSubmit={handleReset}>
                                <div className="login">
                                    <div className="formItem">
                                        <label className="fi__label">
                                            <input
                                                type="password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => {
                                                    const { value } = e.target;

                                                    setPassword(value);
                                                }}
                                                placeholder=""
                                            />
                                            <span className="fi__span">
                                                Password
                                            </span>
                                        </label>
                                    </div>
                                    <div className="formItem">
                                        <button className="btn" type="submit">
                                            Reset Password
                                        </button>
                                    </div>
                                    <div className="formItem fwItem lastItem forgotten">
                                        <span>
                                            <Link to="/portal/forgot">
                                                <ArrowLeftIcon size={24} />
                                                Go Back
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
